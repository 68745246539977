.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grey-font {
  text-align: center;
  margin-bottom: 1em;
  font-size: 0.9em;
  color: rgb(127, 122, 122);
}

.grey-font-bigger {
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.2em;
  color: rgb(127, 122, 122);
}

.annotation-1 {
  border-bottom: 0.15em solid lightsalmon;
  transition: all 0.25s;
}

.annotation-1:hover {
  background-color: lightsalmon;
}

.annotation-1:hover ~ .text-editor-title {
  background-color: red;
}

.annotation-1-color {
  background-color: lightsalmon !important;
}

.annotation-2 {
  border-bottom: 0.15em solid pink;
  transition: all 0.25s;
}

.annotation-2:hover {
  background-color: pink;
}

.annotation-2-color {
  background-color: pink;
}

.annotation-3 {
  border-bottom: 0.15em solid violet;
  transition: all 0.25s;
}

.annotation-3:hover {
  background-color: violet;
}

.annotation-3-color {
  background-color: violet;
}

.annotation-4 {
  border-bottom: 0.15em solid cyan;
  transition: all 0.25s;
}

.annotation-4:hover {
  background-color: cyan;
}

.annotation-4-color {
  background-color: cyan;
}

.annotation-5 {
  border-bottom: 0.15em solid cyan;
  transition: all 0.25s;
}

.annotation-5:hover {
  background-color: lightgreen;
}

.annotation-5-color {
  background-color: lightgreen;
}

.annotation-6 {
  border-bottom: 0.15em solid cyan;
  transition: all 0.25s;
}

.annotation-6:hover {
  background-color: gold;
}

.annotation-6-color {
  background-color: gold;
}

.text-editor {
  width: 100%;
  overflow-y: auto;
  height: 42rem;
  max-height: 42rem;
  font-size: 1rem;
  line-height: 150%;
}

.open-transition {
  transition: all 0.5s;
}

.feedback-button {
  background-color: lightblue;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 25rem;
  font-size: 1.5rem;
  height: 2.5rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pie-chart-container {
  transform: scale(0.65);
}

.login-page-container {
  padding-top: 60px;
  padding-bottom: 200px;
  padding-right: 400px;
  padding-left: 400px;
  /* background-color: rgb(167, 205, 171); */
  background-image: linear-gradient(rgb(142, 187, 222), rgb(66, 182, 162))
}

.admin-page-container {
  padding-top: 30px;
  padding-bottom: 200px;
  padding-right: 400px;
  padding-left: 400px;
  /* background-color: rgb(167, 205, 171); */
  background-image: linear-gradient(rgb(142, 187, 222), rgb(66, 182, 162))
}

.fallstudie-page-container {
  padding-top: 30px;
  padding-bottom: 200px;
  padding-right: 200px;
  padding-left: 200px;
  background-image: linear-gradient(rgb(142, 187, 222), rgb(66, 182, 162));
}

@media (min-width: 1025) {
  .pie-chart-container {
    transform: scale(0.78);
  }
}

@media (min-width: 2510px) {
  .pie-chart-container {
    transform: scale(1);
  }
  .useful-paragraphs {
    margin-left: 200px;
  }
}

@media (min-width: 3000px) {
  .pie-chart-container {
    transform: scale(1.3);
  }
}
.plan-item-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 2rem;
}

.plan-item-button {
  background-color: gray;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
}

.planTextItemContainer {
  display: flex;
  flex-direction: column;
}

.planTextItem {
  display: flex;
	padding: 0.5rem;
  gap: 10px;
}

.planTextItemTextArea {
  min-height: 80px;
  padding: 1rem;
  flex-grow: 1;
	border-radius: 4px;
	border: none;
}

.planTextItemTextAreaSmall {
	min-height: 20px !important;
}
